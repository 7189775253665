import React, { useState, useContext, useEffect } from "react";
import { navigate } from "gatsby"

import { UserContext } from '../context/UserContext'
import { ClientContext } from "../context/ClientContext";
import { StoreContext } from "../context/Store";

import OrderOption from './OrderOption'
import Product from "./shopify/Product";

function OrderForm(props) {
  const [name, setName] = useState();
  const [message, setMessage] = useState("");
  const [timeSlotVariantId, setTimeSlotVariantId] = useState(null);
  const [slicesOfCheese, setSlicesOfCheese] = useState("true");
  const [withJalepenos, setWithJalepenos] = useState("true");

   const { store, dispatch } = useContext(StoreContext);
   const {
     store: { client },
   } = useContext(ClientContext);

   function addVariantToCart(variantId, quantity) {
     const lineItemsToAdd = [
       {
         variantId,
         quantity: parseInt(quantity, 10),
         customAttributes: [
           { key: "Name", value: name },
           {
             key: "Details",
             value: `${
               slicesOfCheese === "true"
                 ? "Cheese"
                 : "No Cheese"
             }  ${withJalepenos == "true" ? "and Jalepenos" : ""}`,
           },
         ],
       },
     ];
     const checkoutId = store.checkout.id;

     return client.checkout
       .addLineItems(checkoutId, lineItemsToAdd)
       .then((response) => {
         dispatch({ type: "setCheckout", payload: response });
       });
   }
  const user = useContext(UserContext);

  function handleSubmit(e) {
    if (formValid()){
      console.log("PLACEING ORDER")
      addVariantToCart(timeSlotVariantId, 1);
    }
    e.preventDefault();
  }

  function formValid(){
    return timeSlotVariantId && timeSlotAvailable() && name;
  }

  useEffect(() => {
    if (store.checkout.lineItems) displayErrorMessage();
  }, [store, name, timeSlotVariantId]);

  function displayErrorMessage(){
    console.log("HGEE")
    if (!timeSlotVariantId){
      setMessage("Please select an available time slot.");
    } else if (!timeSlotAvailable()) {
      setMessage("That Time Slot is Sold Out.");
    } else if (!name) {
      setMessage("Please Enter a Name for the Order");
    } else {
      const cartVariantMap = store.checkout.lineItems.map((l) => {
        return { id: l.variant.id, quantity: l.quantity };
      });

      const amountInCart = cartVariantMap
        .filter((v) => v.id === timeSlotVariantId)
        .reduce((a, b) => a + b.quantity, 0);

      const quantityAvailable = store.products
        .filter((p) => p.title === "Double Up")[0]
        .options[0].values.filter((v) => v.id === timeSlotVariantId)[0]
        .quantityAvailable;
      setMessage(
        `${quantityAvailable -
          amountInCart} burgers are available at this time slot.`
      );
    }
  }

  function timeSlotAvailable() {
    if (timeSlotVariantId && store.products){
      const cartVariantMap = store.checkout.lineItems.map((l) => {
        return { id: l.variant.id, quantity: l.quantity };
      });

      const amountInCart = cartVariantMap
        .filter((v) => v.id === timeSlotVariantId)
        .reduce((a, b) => a + b.quantity, 0);

      const quantityAvailable = store.products
        .filter((p) => p.title === "Double Up")[0]
        .options[0].values.filter((v) => v.id === timeSlotVariantId)[0]
        .quantityAvailable;

      return amountInCart < quantityAvailable;
    }
    return true;
  }


  return (
    <div
      style={{
        backgroundColor: "#eee",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          paddingBottom: "20px",
        }}
        className="order-form-container"
      >
        <h2 className="is-size-1 has-text-weight-bold is-bold-light order-form-title">
          July 18th Drive Thru
          <br />
          <span className="is-size-3" style={{ fontStyle: "italic" }}>
            Double Up - In Person
          </span>
        </h2>
        <form onSubmit={handleSubmit}>
          {store.products.map((product) => {
            if (product.title == "Double Up") {
              const cartVariantMap =
                store.checkout.lineItems &&
                store.checkout.lineItems.map((l) => {
                  return { id: l.variant.id, quantity: l.quantity };
                });
              const timeSlotOptions = product.options[0].values.map((value) => {
                return {
                  name: `${value.value} - ${
                    value.quantityAvailable -
                      cartVariantMap
                        .filter((v) => v.id === value.id)
                        .reduce((a, b) => a + b.quantity, 0) >
                    0
                      ? value.quantityAvailable -
                        cartVariantMap
                          .filter((v) => v.id === value.id)
                          .reduce((a, b) => a + b.quantity, 0) +
                        " Available"
                      : "Sold Out"
                  }`,
                  value: value.id,
                  disabled:
                    value.quantityAvailable -
                      cartVariantMap
                        .filter((v) => v.id === value.id)
                        .reduce((a, b) => a + b.quantity, 0) <
                    1,
                };
              });
              return (
                <>
                  <OrderOption
                    prompt="Select a Time Slot"
                    optionsWithValues={timeSlotOptions}
                    setOption={setTimeSlotVariantId}
                    currentValue={timeSlotVariantId}
                    key="timeSlot"
                    notePlacement="before"
                    note="Timeslots spaced in 15 min increments to better allow for social distancing."
                  />
                </>
              );
            }
          })}
          <h3 className="is-size-4 order-form-title">
            <span className="is-size-3 has-text-weight-bold is-bold-light">
              The Original Burger
            </span>
            <br />
            <br />
            The original burger comes with double meat, grilled onions, lettuce,
            mayo, with pickles on the side.
            <br />
            <br />
            We recommend cheese, and if you'd like an extra kick, jalapeños.
            <br />
            <br />
          </h3>
          <OrderOption
            prompt="With Cheese?"
            optionsWithValues={[
              { name: "No Cheese", value: "false" },
              { name: "Cheese", value: "true" },
            ]}
            setOption={setSlicesOfCheese}
            currentValue={slicesOfCheese}
            key="slicesOfCheese"
            color="#F49F0D"
            note="Extra Sharp Tillamook Cheddar"
          />
          <OrderOption
            prompt="With Jalepenos?"
            optionsWithValues={[
              { name: "No", value: "false" },
              { name: "Yes", value: "true" },
            ]}
            setOption={setWithJalepenos}
            currentValue={withJalepenos}
            key="withJalepenos"
            color="#096f09"
            note="Adds a nice kick!"
          />
          <div className="order-form-text-input-container order-form-group">
            <label style={{ fontWeight: 700 }}>Name:</label>
            <input
              type="text"
              name="name"
              placeholder="What's your name?"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <h3 className="is-size-4 order-form-title">
            <span className="has-text-weight-bold is-bold-light">
              Price: $10.00
            </span>
            <br />
            <br />
            Order includes 1 double burger.
          </h3>
          {message && <h3 className="is-size-5 order-form-title">{message}</h3>}
          <input
            className="btn order-form-submit-button"
            type="submit"
            disabled={!formValid()}
            value="Add to Cart"
          />
        </form>
      </div>
    </div>
  );
}

export default OrderForm;
