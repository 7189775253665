import React from 'react'

function OrderOption({
  prompt,
  optionsWithValues,
  setOption,
  currentValue,
  key,
  note,
  color,
  notePlacement,
}) {
  return (
    <div
      className="order-form-group"
      style={{
        backgroundColor:
          color && currentValue !== "false" && currentValue !== "0"
            ? color
            : "",
      }}
    >
      <label
        className="order-form-prompt"
        style={{
          fontWeight: 700,
          mixBlendMode:
            color && currentValue !== "false" && currentValue !== "0"
              ? "soft-light"
              : "",
        }}
      >
        {prompt}
        {notePlacement === "before" && note && (
          <div
            className="order-form-group-note"
            style={{
              mixBlendMode:
                color && currentValue !== "false" && currentValue !== "0"
                  ? "soft-light"
                  : "",
            }}
          >
            {note}
          </div>
        )}
      </label>
      <div className="order-form-options">
        {optionsWithValues.map((optionValue) => {
          let selected = currentValue == optionValue.value;

          return (
            <label
              className={`order-form-option-label ${
                selected ? "selected" : ""
              } ${optionValue.disabled ? "disabled" : ""}`}
            >
              <input
                type="radio"
                name={key}
                value={optionValue.value}
                checked={selected}
                disabled={optionValue.disabled}
                onChange={(e) => setOption(e.target.value)}
              />
              {optionValue.name}
            </label>
          );
        })}
      </div>
      {notePlacement !== "before" && note && (
        <div
          className="order-form-group-note"
          style={{
            mixBlendMode:
              color && currentValue !== "false" && currentValue !== "0"
                ? "soft-light"
                : "",
          }}
        >
          {note}
        </div>
      )}
    </div>
  );
}

export default OrderOption;
