import React from 'react'

import Layout from '../components/Layout'
import OrderFormDoubleUp from "../components/OrderFormDoubleUp";

function OrderPage(props) {
  return (
    <Layout>
      <section
        style={{
          color: "black",
        }}
      >
        <OrderFormDoubleUp />
      </section>
    </Layout>
  );
}

export default OrderPage
